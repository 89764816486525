import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";

const PopupComponent = ({
  show,
  onHide,
  onYes,
  ModalTitle,
  ModalBody
}) => {


  return (
    <>

      <Modal
        show={show}
        onHide={onHide}
        animation={false}
        backdrop="static"
        centered
      >
        <Modal.Header>
          <Modal.Title className="modal-title text-center text-green">
            {ModalTitle}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            {ModalBody}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-danger ms-1"
            style={{ padding: "7px 20px", borderRadius: "6px", border: "none" }}
            onClick={onHide}
          >
            No
          </Button>
          <Button
            className="btn_modal"
            style={{ padding: "7px 20px", borderRadius: "6px", border: "none" }}
            onClick={onYes}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PopupComponent;
