import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import {
  postSendAllDataForMailOfRefreePopupApplicationManager,
  getAllDataForRefreeDetailsPopupMailAppManager,
} from "../../../../../api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../../Widgets/Loader/Loader";
import VerificationModal from "../VerificationModal/VerificationModal";

const EditRefereeDetailsModal_S = ({
  show,
  handleClose,
  id,
  call,
  Employ_id,
  getAllDataOfSubmittedApplicationsFunction
}) => {

  // console.log(show,
  //   handleClose,
  //   id,
  //   call,
  //   Employ_id);
  const [loading, setLoading] = useState(false);
  const [RefereeName, setRefereeName] = useState("");
  const [ApplicantName, setApplicantName] = useState("");

  const [CompanyName, setCompanyName] = useState("");
  const [RTOEmail, setRTOEmail] = useState("");
  const [qualificationLinks, setQualificationLinks] = useState([]);
  const [QualificationDocs, setQualificationDocs] = useState({});
  const [emailRTOError, setEmailRTOError] = useState("");
  const [showVerificationModal, setShowVerificationModal] = useState(false);

  const handleRefereeName = (event) => {
    setRefereeName(event.target.value);
  };

  const handleApplicantName = (event) => {
    setApplicantName(event.target.value);
  };

  const handleCompanyName = (event) => {
    setCompanyName(event.target.value);
  };

  const handleRTOEmail = (event) => {
    const emailValue = event.target.value;
    setRTOEmail(emailValue);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(emailValue)) {
      setEmailRTOError("Please enter a valid email address.");
    } else {
      setEmailRTOError("");
    }
  };

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setQualificationDocs({
      ...QualificationDocs,
      [id]: checked,
    });
  };

  const sendAllData = async (e) => {
    if (!ApplicantName || !RefereeName || !CompanyName || !RTOEmail) {
      toast.error("Please fill in all required fields.");
      return;
    }

    // Show confirmation modal before proceeding
    setShowVerificationModal(true);
  };

  const handleChangeStatus = async () => {
    const formData = new FormData();
    formData.append("pointer_id", id);
    formData.append("employer_id", Employ_id);
    formData.append("applicant_name", ApplicantName);
    formData.append("referee_name", RefereeName);
    formData.append("organisation_name", CompanyName);
    formData.append("referee_email", RTOEmail);
    // Object.keys(QualificationDocs).forEach((docId) => {
    //   if (QualificationDocs[docId]) {
    //     formData.append("document_ids[]", docId);
    //   }
    // });

    const documentIds = Object.keys(QualificationDocs)
      .filter((docId) => QualificationDocs[docId])
      .join(",");

      formData.append("document_ids", documentIds);


    // if (documentIds) {
    //   formData.append("document_ids", documentIds);
    // }

    try {
      setLoading(true);
      const response =
        await postSendAllDataForMailOfRefreePopupApplicationManager(formData);

      setLoading(false);

      if (response?.data?.response?.response === true) {
        toast.success(
          response?.data?.response?.success_msg ||
            "Updated and Mail Sent Successfully."
        );
        // getIndividualEmploymentFunction(id);
        handleClose();
        setShowVerificationModal(false);
        getAllDataOfSubmittedApplicationsFunction();
      } else {
        toast.error(response?.data?.response?.error_msg || "Failed to update.");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating stage status data:", error);
      
    }
  };

  const getAllDataFun = async () => {
    try {
      setLoading(true);
      const response = await getAllDataForRefreeDetailsPopupMailAppManager(
        id,
        Employ_id
      );

      setLoading(false);

      if (response?.data?.response?.response === true) {
        const responseData = response?.data?.response?.data;
        setApplicantName(responseData?.applicant_name);
        setRefereeName(responseData?.referee_name);
        setCompanyName(responseData?.organisation_name);
        setRTOEmail(responseData?.referee_email);

        const docSelections = {};
        responseData?.document_data.forEach((doc) => {
          if (responseData?.document_data?.includes(doc?.document_id)) {
            docSelections[doc?.document_id] = true;
          }
        });
        setQualificationDocs(docSelections);
        setQualificationLinks(responseData?.document_data);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (id && call) {
      getAllDataFun();
    }
  }, [Employ_id, call]);

  return (
    <>
      {loading && <Loader />}
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        centered
        className={showVerificationModal ? "blur-background" : ""}
    
      >
        <Modal.Header>
          <Modal.Title className="modal-title text-center text-green">
            <h5>Edit Referee Details</h5>
          </Modal.Title>
          <button
            type="button"
            className="close"
            style={{ background: "none", border: "none", fontSize: "1.5rem" }}
            onClick={handleClose}
          >
            <FaTimes />
          </button>
        </Modal.Header>

        <Modal.Body>
          {/* Referee Name */}
          <div className="zoom_details">
            <label htmlFor="referee-name" className="form-label">
              <b style={{ fontSize: "12px" }}>
                Applicant Name <span className="text-danger">*</span>
              </b>
            </label>
            <input
              type="text"
              className="form-control"
              id="referee-name"
              value={ApplicantName}
              onChange={handleApplicantName}
              required
              disabled
            />
          </div>

          {/* Company Name */}
          <div className="zoom_details">
            <label htmlFor="company-name" className="form-label">
              <b style={{ fontSize: "12px" }}>
                Company/Organization Name <span className="text-danger">*</span>
              </b>
            </label>
            <input
              type="text"
              className="form-control"
              id="company-name"
              value={CompanyName}
              onChange={handleCompanyName}
              required
              disabled
            />
          </div>

          {/* Qualification Documents */}
          <div className="zoom_details">
            <label htmlFor="qualification-docs" className="form-label">
              <b style={{ fontSize: "12px" }}>Select Document:</b>
            </label>
            {qualificationLinks.map((doc) => (
              <Form.Check
                key={doc?.document_id}
                type="checkbox"
                id={doc?.document_id}
                label={
                  <a
                    href={doc.document_path}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {doc?.document_name}
                  </a>
                }
                checked={QualificationDocs[doc?.document_id] || false}
                onChange={handleCheckboxChange}
              />
            ))}
          </div>

          <div className="zoom_details">
            <label htmlFor="referee-name" className="form-label">
              <b style={{ fontSize: "12px" }}>
                Referee Name <span className="text-danger">*</span>
              </b>
            </label>
            <input
              type="text"
              className="form-control"
              id="referee-name"
              value={RefereeName}
              onChange={handleRefereeName}
              required
            />
          </div>

          {/* Referee Email */}
          <div className="zoom_details">
            <label htmlFor="rto-email" className="form-label">
              <b style={{ fontSize: "12px" }}>
                Referee Email Id <span className="text-danger">*</span>
              </b>
            </label>
            <input
              type="email"
              className="form-control"
              id="rto-email"
              value={RTOEmail}
              onChange={handleRTOEmail}
              required
            />
            {emailRTOError && <p className="text-danger">{emailRTOError}</p>}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            className="btn_yellow_green"
            onClick={handleClose}
            style={{ padding: "7px 20px", borderRadius: "6px" }}
          >
            Close
          </button>
          <button
            className="btn_modal"
            style={{ padding: "7px 20px", borderRadius: "6px" }}
            onClick={sendAllData}
          >
            Save & Send
          </button>
        </Modal.Footer>
      </Modal>

  
      <VerificationModal
        show={showVerificationModal}
        onClose={() => setShowVerificationModal(false)}
        onYes={handleChangeStatus}
        Title="Confirm Send Email!"
        Message={
          <>
            Are you sure you want to send Verification Email to{" "}
            <span style={{ fontWeight: "bold", color: "#055837" }}>
              {RefereeName}
            </span>{" "}
            at{" "}
            <span style={{ fontWeight: "bold", color: "#055837" }}>
              {RTOEmail}
            </span>{" "}
            address?
          </>
        }
      />
    </>
  );
};

export default EditRefereeDetailsModal_S;
