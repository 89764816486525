import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import "./Offline Files.css";
import SAQ from "./SAQ/SAQ.js";

import SidebarMenu from "../Widgets/SideBar Menu/SidebarMenu";
import ApplicantKit from "./Applicant Kit/ApplicantKit.js";
import ThirdPartyReport from "./Third Party Report/ThirdPartyReport.js";
import ApplicationForm from "./ApplicationForm/ApplicationForm.js";

const OfflineFiles = () => {
  const [SAQActive, setSAQActive] = useState(false);
  const [ApplicationKitActive, setApplicationKitActive] = useState(false);
  const [TPRActive, setTPRActive] = useState(false);
  const [ApplicationFormActive, setApplicationFormActive] = useState(false);

  useEffect(() => {
    if (localStorage.getItem(`offline_files_saq_tab_active`) === "true") {
      setSAQActive(true);
      setApplicationKitActive(false);
      setTPRActive(false);
    } else if (
      localStorage.getItem(`offline_files_applicant_kit_active_tab`) === "true"
    ) {
      setApplicationKitActive(true);
      setSAQActive(false);
      setTPRActive(false);
    } else if (
      localStorage.getItem(`offline_files_third_party_report_active_tab`) ===
      "true"
    ) {
      setTPRActive(true);
      setApplicationKitActive(false);
      setSAQActive(false); // Set view update tab active to false
    } else {
      setSAQActive(true);
      setApplicationKitActive(false);
      setTPRActive(false);
    }
  }, []);

  return (
    <>
      <div className="main_desktop">
        <hr className="mb-2 thick-hr" />

        <div className="main-wrapper">
          <div className="sub-wrapper-sidebar">
            <SidebarMenu />
          </div>

          <div className="sub-wrapper-main">
            <div className="main-heading-title">
              <h3 className="heading-admin-title text-color-green">
                Offline Files
              </h3>
            </div>
            <div className="main-body-area mb-5">
              <Card className="shadow">
                <div className="card card-primary card-tabs">
                  <div className="card-header p-0 border-bottom-0">
                    <ul className="nav nav-tabs">
                      <li
                        className="nav-item"
                        style={{ marginTop: "0px", cursor: "pointer" }}
                      >
                        <a
                          className={`nav-link nav_inactive_tab_btn ${
                            SAQActive ? "nav_active_tab_btn" : ""
                          }`}
                          id="tabs_view_edit"
                          // href="#"
                          role="tab"
                          aria-controls="tabs_view_edit"
                          aria-selected={SAQActive ? "true" : "false"}
                          onClick={() => {
                            setSAQActive(true);
                            setApplicationKitActive(false);
                            setTPRActive(false);
                            setApplicationFormActive(false);

                            localStorage.setItem(
                              `offline_files_saq_tab_active`,
                              "true"
                            );
                            localStorage.setItem(
                              `offline_files_applicant_kit_active_tab`,
                              "false"
                            );
                            localStorage.setItem(
                              `offline_files_third_party_report_active_tab`,
                              "false"
                            );
                            localStorage.setItem(
                              `offline_files_application_form_report_active_tab`,
                              "false"
                            );
                          }}
                        >
                          <h6 className="h6">SAQ</h6>
                        </a>
                      </li>
                      <li className="nav-item" style={{ cursor: "pointer" }}>
                        <a
                          className={`nav-link nav_inactive_tab_btn ${
                            ApplicationKitActive ? "nav_active_tab_btn" : ""
                          }`}
                          id="tabs_documents"
                          // href="#"
                          role="tab"
                          aria-controls="tabs_documents"
                          aria-selected={
                            ApplicationKitActive ? "true" : "false"
                          }
                          onClick={() => {
                            setApplicationKitActive(true);
                            setSAQActive(false);
                            setTPRActive(false);
                            setApplicationFormActive(false);

                            localStorage.setItem(
                              `offline_files_saq_tab_active`,
                              "false"
                            );
                            localStorage.setItem(
                              `offline_files_applicant_kit_active_tab`,
                              "true"
                            );
                            localStorage.setItem(
                              `offline_files_application_form_report_active_tab`,
                              "false"
                            );
                            localStorage.setItem(
                              `offline_files_third_party_report_active_tab`,
                              "false"
                            );
                          }}
                        >
                          <h6 className="h6">Applicant Kit</h6>
                        </a>
                      </li>

                      <li className="nav-item" style={{ cursor: "pointer" }}>
                        <a
                          className={`nav-link nav_inactive_tab_btn ${
                            TPRActive ? "nav_active_tab_btn" : ""
                          }`}
                          id="offline_files_third_party_report_active_tab"
                          onClick={() => {
                            setTPRActive(true);
                            setSAQActive(false);
                            setApplicationKitActive(false);
                            setApplicationFormActive(false);

                            localStorage.setItem(
                              `offline_files_saq_tab_active`,
                              "false"
                            );
                            localStorage.setItem(
                              `offline_files_applicant_kit_active_tab`,
                              "false"
                            );
                            localStorage.setItem(
                              `offline_files_application_form_report_active_tab`,
                              "false"
                            );
                            localStorage.setItem(
                              `offline_files_third_party_report_active_tab`,
                              "true"
                            );
                          }}
                          role="tab"
                          aria-controls="offline_files_third_party_report_active_tab"
                          aria-selected={TPRActive ? "true" : "false"}
                        >
                          <h6 className="h6">Third Party Report</h6>
                        </a>
                      </li>

                        {/* Application Form */}

                        <li className="nav-item" style={{ cursor: "pointer" }}>
                        <a
                          className={`nav-link nav_inactive_tab_btn ${
                            ApplicationFormActive ? "nav_active_tab_btn" : ""
                          }`}
                          id="offline_files_third_party_report_active_tab"
                          onClick={() => {
                            setApplicationFormActive(true);
                            setTPRActive(false);
                            setSAQActive(false);
                            setApplicationKitActive(false);
                            

                            localStorage.setItem(
                              `offline_files_saq_tab_active`,
                              "false"
                            );
                            localStorage.setItem(
                              `offline_files_applicant_kit_active_tab`,
                              "false"
                            );
                            localStorage.setItem(
                              `offline_files_third_party_report_active_tab`,
                              "false"
                            );
                            localStorage.setItem(
                              `offline_files_application_form_report_active_tab`,
                              "true"
                            );
                          }}
                          role="tab"
                          aria-controls="offline_files_third_party_report_active_tab"
                          aria-selected={ApplicationFormActive ? "true" : "false"}
                        >
                          <h6 className="h6">Forms</h6>
                        </a>
                      </li>

                    </ul>
                  </div>
                </div>

                <div className="mt-3">
                  {SAQActive && <SAQ />}
                  {ApplicationKitActive && <ApplicantKit />}
                  {TPRActive && <ThirdPartyReport />}
                  {ApplicationFormActive && <ApplicationForm />}
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OfflineFiles;
