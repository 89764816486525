import React, { useState, useEffect } from "react";
import { Container, Spinner, Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Submitted Docs.css";
import Loader from "../../Widgets/Loader/Loader";
import { getAllSubmittedApplicationsOfAppManager } from "../../../api";
import { useLocation } from "react-router-dom";

import Stage1 from "./Stage1/Stage1";
import Stage2 from "./Stage2/Stage2";
import Stage3 from "./Stage3/Stage3";
import Stage_3_R from "./Stage3_R/Stage_3_R";
import Stage4 from "./Stage4/Stage4";
import Stage2_R from "./Stage2_R/Stage2";

const SubmittedDocs = ({ AllDataFromApiForSubmitted,pointerID }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  // const { pointerID } = location.state || {};

  const [AllDataFromApi, setAllDataFromApi] = useState({});
  const [currentStageKey, setCurrentStageKey] = useState("");
  const [trueStages, setTrueStages] = useState({});

  const getAllDataOfSubmittedApplicationsFunction = async () => {
    try {
      setLoading(true);

      const response = await getAllSubmittedApplicationsOfAppManager(pointerID);

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        setAllDataFromApi(response?.data?.response?.data);
      } else {
        setLoading(false);
        console.error(
          "Error fetching data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getAllDataOfSubmittedApplicationsFunction();
  }, []);

  useEffect(() => {
    if (AllDataFromApiForSubmitted) {
      setAllDataFromApi(AllDataFromApiForSubmitted);
    }
  }, [AllDataFromApiForSubmitted]);

  useEffect(() => {
    const currentStage = AllDataFromApi?.current_stage;

    if (currentStage) {
      let stageKey = currentStage.replace("stage_", "");

      // Convert "3_R" to "5"
      if (stageKey === "3_R") {
        stageKey = "5";
      }

      // Convert "2_R" to "6"
      if (stageKey === "2_R") {
        stageKey = "6";
      }

      setCurrentStageKey(stageKey);
    } else {
      setCurrentStageKey("");
    }

    const overStageStatus = AllDataFromApi?.over_stage_status || {};
    setTrueStages(overStageStatus);
  }, [AllDataFromApi]);


  const crossCheckEnableCommentBtn = (currentStatus) => {
    // console.log(currentStatus, "currentStatus");
    const checkingEnableCommentBtnArray = ["Submitted", "Lodged", "In Progress"];
    // console.log(!checkingEnableCommentBtnArray.includes(currentStatus), "!checkingEnableCommentBtnArray.includes(currentStatus)");
    return !checkingEnableCommentBtnArray.includes(currentStatus);
  }

  return (
    <>
      {loading && <Loader />}

      {currentStageKey !== "" && !loading && (
        <Accordion defaultActiveKey={["0", currentStageKey]} flush alwaysOpen>
          {trueStages?.stage_1 === true && (
            <Stage1
              current_status = {crossCheckEnableCommentBtn(AllDataFromApi?.page_data?.stage_1?.current_status)}
              current_stage = {AllDataFromApi?.current_stage}
              is_doc_deleted={AllDataFromApi?.is_doc_deleted}
              ApiPageData={AllDataFromApi?.page_data?.stage_1}
              pointerID={pointerID}
              getAllDataOfSubmittedApplicationsFunction={
                getAllDataOfSubmittedApplicationsFunction
              }
              agentEmail = {AllDataFromApi?.topbar_data?.agent_email}
            />
          )}
          {trueStages?.stage_2 === true && (
            <Stage2
              current_status = {crossCheckEnableCommentBtn(AllDataFromApi?.page_data?.stage_2?.current_status)}
              current_stage = {AllDataFromApi?.current_stage}
              is_doc_deleted={AllDataFromApi?.is_doc_deleted}
              ApiPageData={AllDataFromApi?.page_data?.stage_2}
              pointerID={pointerID}
              getAllDataOfSubmittedApplicationsFunction={
                getAllDataOfSubmittedApplicationsFunction
              }
            />
          )}

          {trueStages?.stage_2_R === true && (
            <Stage2_R
              current_status = {crossCheckEnableCommentBtn(AllDataFromApi?.page_data?.stage_2_R?.current_status)}
              current_stage = {AllDataFromApi?.current_stage}
              is_doc_deleted={AllDataFromApi?.is_doc_deleted}
              ApiPageData={AllDataFromApi?.page_data?.stage_2_R}
              pointerID={pointerID}
              getAllDataOfSubmittedApplicationsFunction={
                getAllDataOfSubmittedApplicationsFunction
              }
            />
          )}
          
          {trueStages?.stage_3 === true && (
            <Stage3
              current_status = {crossCheckEnableCommentBtn(AllDataFromApi?.page_data?.stage_3?.current_status)}
              current_stage = {AllDataFromApi?.current_stage}
              is_doc_deleted={AllDataFromApi?.is_doc_deleted}
              ApiPageData={AllDataFromApi?.page_data?.stage_3}
              pointerID={pointerID}
              getAllDataOfSubmittedApplicationsFunction={
                getAllDataOfSubmittedApplicationsFunction
              }
            />
          )}

          {trueStages?.stage_3_R === true && (
            <Stage_3_R
              current_status = {crossCheckEnableCommentBtn(AllDataFromApi?.page_data?.stage_3_R?.current_status)}
              current_stage = {AllDataFromApi?.current_stage}
              is_doc_deleted={AllDataFromApi?.is_doc_deleted}
              ApiPageData={AllDataFromApi?.page_data?.stage_3_R}
              pointerID={pointerID}
              getAllDataOfSubmittedApplicationsFunction={
                getAllDataOfSubmittedApplicationsFunction
              }
            />
          )}

          {trueStages?.stage_4 === true && (
            <Stage4
              current_status = {crossCheckEnableCommentBtn(AllDataFromApi?.page_data?.stage_4?.current_status)}
              current_stage = {AllDataFromApi?.current_stage}
              is_doc_deleted={AllDataFromApi?.is_doc_deleted}
              ApiPageData={AllDataFromApi?.page_data?.stage_4}
              pointerID={pointerID}
              getAllDataOfSubmittedApplicationsFunction={
                getAllDataOfSubmittedApplicationsFunction
              }
            />
          )}
        </Accordion>
      )}
    </>
  );
};

export default SubmittedDocs;
