import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import "./Agent_Applicant_data.css";
import Reminder_Date_Wrapper from "./Reminder_Date_Wrapper";
import VerificationModal from "./VerificationModal/VerificationModal";
import { useNavigate } from "react-router-dom";

const Agent_Applicant_data = ({
  setLoading,
  AgentApplicantTiButton,
  API_Data,
  pointerID,
  getAllDataOfViewUpdateFunction,
}) => {
  const navigate = useNavigate();
  const accountType = localStorage.getItem("AQATO_SuperAdminAccountType") || "";
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.clientHeight); // Set height based on content column
    }
  }, [API_Data]);

  const handleRequestAdditionalInfoModalOpen = () => {
    setShowVerificationModal(true);
  };

  const handleVerificationModalClose = () => {
    setShowVerificationModal(false);
  };

  const handleNavigateToApplicationTransfer = () => {
    navigate("/admin/application_manager/view_application/transfer_applicant", {
      state: { pointerID: pointerID },
    });
  };

  const handleToggleImageModal = () => {
    setShowImageModal((prev) => !prev);
  };

  return (
    <>
      <div className="Sub-heading-title">
        <Row>
          {/* Content section */}
          <Col lg={8} ref={contentRef}>
            {accountType !== "head_office" && (
              <Row>
                <Col>
                  <div
                    style={{
                      fontWeight: "bold",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {API_Data?.heading_text_4}
                  </div>
                </Col>
              </Row>
            )}

            <Row className="align-items-center">
              <Col className="d-flex align-items-center">
                <div
                  style={{
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {API_Data?.heading_text_1}
                </div>
                {API_Data?.profile_path && (
                  <button
                    onClick={handleToggleImageModal}
                    className="btn btn-sm btn_green_yellow mx-2 p-0 px-1"
                    style={{ marginLeft: "8px" }}
                  >
                    <i className="bi bi-person-circle icon-transition"></i>
                  </button>
                )}
              </Col>
            </Row>

            {accountType !== "head_office" && (
              <Row>
                <Col>
                  <div
                    style={{
                      fontWeight: "bold",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {API_Data?.heading_text_2}
                    {API_Data?.agent_contract_details !== "" && (
                      <button
                        onClick={handleRequestAdditionalInfoModalOpen}
                        className="btn btn-sm btn_green_yellow mx-2 p-0 px-1"
                      >
                        <i className="bi bi-person-check-fill icon-transition"></i>
                      </button>
                    )}
                  </div>
                </Col>
              </Row>
            )}

            {accountType !== "head_office" && (
              <Row>
                <Col>
                  <div
                    style={{
                      fontWeight: "bold",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {API_Data?.heading_text_3}
                    <button
                      onClick={handleNavigateToApplicationTransfer}
                      className="btn btn-sm btn_green_yellow mx-2 p-0 px-1"
                      disabled = {API_Data?.is_doc_deleted === "1" ? true : false}
                    >
                      <i className="bi bi-pencil-square"></i>
                    </button>
                  </div>
                </Col>
              </Row>
            )}
          </Col>

          {/* Reminder section */}
          <Col lg={4}>
            {accountType !== "head_office" && (
              <Reminder_Date_Wrapper
                setLoading={setLoading}
                AgentApplicantTiButton={AgentApplicantTiButton}
                API_Data={API_Data}
                pointerID={pointerID}
                getAllDataOfViewUpdateFunction={getAllDataOfViewUpdateFunction}
              />
            )}
          </Col>
        </Row>
      </div>

      <Modal
        show={showImageModal}
        onHide={handleToggleImageModal}
        centered
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#055837" }}>
            Passport Size Photo
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <img
            src={API_Data?.profile_path}
            alt="Passport Size Photo"
            style={{
              borderRadius: "10px",
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
              border: "2px solid #fecc00",
              margin: "0 auto",
            }}
          />
        </Modal.Body>
      </Modal>

      <VerificationModal
        show={showVerificationModal}
        onClose={handleVerificationModalClose}
        onYes={handleRequestAdditionalInfoModalOpen}
        Title="Additional Contact Details"
        Message={
          <span style={{ fontWeight: "bold", color: "black" }}>
            {API_Data?.agent_contract_details}
          </span>
        }
      />
    </>
  );
};

export default Agent_Applicant_data;















// import React, { useState, useEffect, useRef } from "react";
// import { Row, Col, Modal } from "react-bootstrap";
// import "./Agent_Applicant_data.css";
// import Reminder_Date_Wrapper from "./Reminder_Date_Wrapper";
// import VerificationModal from "./VerificationModal/VerificationModal";
// import { useNavigate } from "react-router-dom";

// const Agent_Applicant_data = ({
//   API_Data,
//   pointerID,
//   getAllDataOfViewUpdateFunction,
// }) => {
//   const navigate = useNavigate();
//   const accountType = localStorage.getItem("AQATO_SuperAdminAccountType") || "";
//   const [showVerificationModal, setShowVerificationModal] = useState(false);
//   const [showImageModal, setShowImageModal] = useState(false);
//   const [contentHeight, setContentHeight] = useState(0);
//   const contentRef = useRef(null);

//   useEffect(() => {
//     if (contentRef.current) {
//       setContentHeight(contentRef.current.clientHeight); // Set height based on content column
//     }
//   }, [API_Data]);

//   const handleRequestAdditionalInfoModalOpen = () => {
//     setShowVerificationModal(true);
//   };

//   const handleVerificationModalClose = () => {
//     setShowVerificationModal(false);
//   };

//   const handleNavigateToApplicationTransfer = () => {
//     navigate("/admin/application_manager/view_application/transfer_applicant", {
//       state: { pointerID: pointerID },
//     });
//   };

//   const handleToggleImageModal = () => {
//     setShowImageModal((prev) => !prev);
//   };

//   return (
//     <>
//       <div className="Sub-heading-title">
//         <Row>
//           {/* Content section */}
//           <Col lg={8} ref={contentRef}>
//             {accountType !== "head_office" && (
//               <Row>
//                 <Col>
//                   <div
//                     style={{
//                       fontWeight: "bold",
//                       whiteSpace: "nowrap",
//                       overflow: "hidden",
//                       textOverflow: "ellipsis",
//                     }}
//                   >
//                     {API_Data?.heading_text_4}
//                   </div>
//                 </Col>
//               </Row>
//             )}

//             <Row className="align-items-center">
//               <Col className="d-flex align-items-center">
//                 <div
//                   style={{
//                     fontWeight: "bold",
//                     whiteSpace: "nowrap",
//                     overflow: "hidden",
//                     textOverflow: "ellipsis",
//                   }}
//                 >
//                   {API_Data?.heading_text_1}
//                 </div>
//                 {API_Data?.profile_path && (
//                   <button
//                     onClick={handleToggleImageModal}
//                     className="btn btn-sm btn_green_yellow mx-2 p-0 px-1"
//                     style={{ marginLeft: "8px" }}
//                   >
//                     <i className="bi bi-person-circle icon-transition"></i>
//                   </button>
//                 )}
//               </Col>
//             </Row>

//             {accountType !== "head_office" && (
//               <Row>
//                 <Col>
//                   <div
//                     style={{
//                       fontWeight: "bold",
//                       whiteSpace: "nowrap",
//                       overflow: "hidden",
//                       textOverflow: "ellipsis",
//                     }}
//                   >
//                     {API_Data?.heading_text_2}
//                     {API_Data?.agent_contract_details !== "" && (
//                       <button
//                         onClick={handleRequestAdditionalInfoModalOpen}
//                         className="btn btn-sm btn_green_yellow mx-2 p-0 px-1"
//                       >
//                         <i className="bi bi-person-check-fill icon-transition"></i>
//                       </button>
//                     )}
//                   </div>
//                 </Col>
//               </Row>
//             )}

//             {accountType !== "head_office" && (
//               <Row>
//                 <Col>
//                   <div
//                     style={{
//                       fontWeight: "bold",
//                       whiteSpace: "nowrap",
//                       overflow: "hidden",
//                       textOverflow: "ellipsis",
//                     }}
//                   >
//                     {API_Data?.heading_text_3}
//                     <button
//                       onClick={handleNavigateToApplicationTransfer}
//                       className="btn btn-sm btn_green_yellow mx-2 p-0 px-1"
//                     >
//                       <i className="bi bi-pencil-square"></i>
//                     </button>
//                   </div>
//                 </Col>
//               </Row>
//             )}
//           </Col>

//           {/* Reminder section */}
//           <Col lg={4}>
//             {accountType !== "head_office" && (
//               <Reminder_Date_Wrapper
//                 API_Data={API_Data}
//                 pointerID={pointerID}
//                 getAllDataOfViewUpdateFunction={getAllDataOfViewUpdateFunction}
//               />
//             )}
//           </Col>
//         </Row>
//       </div>

//       <Modal
//         show={showImageModal}
//         onHide={handleToggleImageModal}
//         centered
//         dialogClassName="custom-modal"
//       >
//         <Modal.Header closeButton>
//           <Modal.Title style={{ fontWeight: "bold" ,color:"#055837"}}>Profile</Modal.Title>
//         </Modal.Header>
//         <Modal.Body className="text-center">
//           <img
//             // src="https://wallpapers.com/images/featured/virat-kohli-yc8dfpcjssp3s4se.jpg"
//             src={API_Data?.profile_path}
//             alt="Profile"
//             style={{
//               borderRadius: "10px",
//               width: "460px",
//               height: "300px", 
//               objectFit: "cover",
//               border: "2px solid #fecc00",
//               margin: "0 auto", 
//             }}
//           />
//         </Modal.Body>
//       </Modal>

//       <VerificationModal
//         show={showVerificationModal}
//         onClose={handleVerificationModalClose}
//         onYes={handleRequestAdditionalInfoModalOpen}
//         Title="Additional Contact Details"
//         Message={
//           <span style={{ fontWeight: "bold", color: "black" }}>
//             {API_Data?.agent_contract_details}
//           </span>
//         }
//       />
//     </>
//   );
// };

// export default Agent_Applicant_data;