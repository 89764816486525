import React, { useState, useEffect } from "react";
import { Card, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { Col, Row } from "react-bootstrap";
import NewBookingModal from "./NewBookingModal/NewBookingModal";
import { getNonAquatoInterviewBookingsAPI } from "../../../api";
import { FaCaretDown } from "react-icons/fa";
import Loader from "../../Widgets/Loader/Loader";
import ZoomDetails from "./ZoomDetails/ZoomDetails";
import CancelInterview from "./CancelInterview/CancelInterview";


const NonAqato = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleDataPerPageChange = (event) => {
    setDataPerPageButton(event.target.value);
    setCurrentPage(1);
  };

  const [selectedItem, setSelectedItem] = useState(null);

  const accountType = localStorage.getItem("AQATO_SuperAdminAccountType") || "";

  const [showZoomModal, setZoomShow] = useState(false);
  const handleZoomClose = () => setZoomShow(false);
  const handleZoomShow = (item) => {
    setZoomShow(true);
    setSelectedItem(item);
  };

  const [showCancelInterviewModal, setCancelInterviewShow] = useState(false);
  const handleCancelInterviewClose = () => setCancelInterviewShow(false);
  const handleCancelInterviewShow = (item) => {
    setCancelInterviewShow(true);
    setSelectedItem(item);
  };

  const [showNewInterviewModal, setNewInterviewModalShow] = useState(false);
  const handleNewInterviewModalClose = () => setNewInterviewModalShow(false);
  const handleNewInterviewModalShow = () => setNewInterviewModalShow(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [dataPerPageButton, setDataPerPageButton] = useState("10");
  const visiblePages = 5;
  const totalPages = Math.ceil(originalData.length / dataPerPageButton);
  // const startPage = 1;
  const startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
  const getNonAquatoDataFunction = async () => {
    try {
      setLoading(true);

      const response = await getNonAquatoInterviewBookingsAPI();

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        setOriginalData(response?.data?.response?.data?.not_aqato_s3_model);
        setCurrentPageData(
          response?.data?.response?.data?.not_aqato_s3_model.slice(
            0,
            parseInt(dataPerPageButton)
          )
        );
      } else {
        setLoading(false);
        console.error(
          "Error fetching occupations data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching occupations data:", error);
    }
  };


  const handleFirst = () => {
    setCurrentPage(1);
  };
  
  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  
  const handleNext = () => {
    if (currentPage < Math.ceil(originalData.length / dataPerPageButton)) {
      setCurrentPage(currentPage + 1);
    }
  };
  
  const handleLast = () => {
    setCurrentPage(Math.ceil(originalData.length / dataPerPageButton));
  };
  

  useEffect(() => {
    // Calculate index of the last item on the current page
    const indexOfLastItem = currentPage * dataPerPageButton;
    // Calculate index of the first item on the current page
    const indexOfFirstItem = indexOfLastItem - dataPerPageButton;
    // Slice the original data array to get current page's data
    const currentItems = originalData.slice(indexOfFirstItem, indexOfLastItem);
    // Update current page data state
    setCurrentPageData(currentItems);
  }, [currentPage, dataPerPageButton, originalData]);



  useEffect(() => {
    const indexOfLastItem = currentPage * dataPerPageButton;
    const indexOfFirstItem = indexOfLastItem - dataPerPageButton;
    const currentItems = originalData.slice(indexOfFirstItem, indexOfLastItem);
    setCurrentPageData(currentItems);
  }, [currentPage, dataPerPageButton, originalData]);

  // useEffect(() => {
  //   if (searchQuery === "") {
  //     setCurrentPageData(originalData.slice(0, parseInt(dataPerPageButton)));
  //   } else {
  //     const filteredData = originalData.filter((item) =>
  //       Object.values(item).some(
  //         (value) =>
  //           value &&
  //           value.toString().toLowerCase().includes(searchQuery.toLowerCase())
  //       )
  //     );
  //     setCurrentPageData(filteredData.slice(0, parseInt(dataPerPageButton)));
  //   }
  // }, [searchQuery, dataPerPageButton, originalData]);




  useEffect(() => {
    const startIndex = (currentPage - 1) * dataPerPageButton;
    const endIndex = currentPage * dataPerPageButton;
  
    // Filter the original data based on the search query
    let filteredData = originalData;
    if (searchQuery !== "") {
      filteredData = originalData.filter((item) =>
        Object.values(item).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    }
  
    // Slice the filtered data based on the current page
    const currentPageData = filteredData.slice(startIndex, endIndex);
  
    setCurrentPageData(currentPageData);
  }, [searchQuery, dataPerPageButton, originalData, currentPage]);

  


  useEffect(() => {
    getNonAquatoDataFunction();
  }, []);

  // const handleNavigateToViewApplication = (event, id) => {
  //   event.preventDefault();
  //   navigate(`/admin/application_manager/view_application`, {
  //     state: { pointerID: id },
  //   });
  // };

  const handleNavigateToViewApplication = (event, id) => {
    event.preventDefault();

    // Open the URL in a new tab with pointerID as a query parameter
    window.open(
      `/admin/application_manager/view_application?pointerID=${encodeURIComponent(
        id
      )}`,
      "_blank"
    );
  };

  return (
    <>
      {loading && <Loader />}

      <div className="main-body-area mb-5">
        <Card className="shadow">
          <div className="mt-3">
            <div className="action_buttons_top bg-white action_btn_desk">
              <Row className="my-2 mx-2 align-items-center justify-content-between gx-2">
                <Col xs="auto" className="d-flex align-items-center pe-0">
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                      width: "60px",
                    }}
                  >
                    <select
                      value={dataPerPageButton}
                      onChange={handleDataPerPageChange}
                      style={{
                        width: "100%",
                        padding: "6px",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                        appearance: "none", // Remove default arrow
                        WebkitAppearance: "none", // Safari compatibility
                        MozAppearance: "none", // Firefox compatibility
                        backgroundColor: "white",
                        cursor: "pointer",
                      }}
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    <FaCaretDown
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "5px",
                        transform: "translateY(-50%)",
                        pointerEvents: "none", // Prevent interaction with the icon
                        fontSize: "14px",
                        color: "#333",
                      }}
                    />
                  </div>
                </Col>

                {/* Search input */}
                <Col xs={8} className="d-flex align-items-center pe-0">
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleSearch}
                    className="searchInput form-control"
                  />
                </Col>

                {/* New Booking Button */}
                <Col xs="auto" className="">
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#edit_form2"
                    className="btn btn-sm btn_green_yellow"
                    onClick={handleNewInterviewModalShow}
                    style={{ padding: "8px", float: "right" }}
                  >
                    <i className="bi bi-plus"></i> New Non - AQATO Booking
                  </button>
                </Col>
              </Row>
            </div>

            <div id="table_wrapper" className="dataTables_wrapper no-footer">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-12">
                    <div class="table-responsive table-wrapper">
                      <table
                        class="table table-striped table-hover dataTable no-footer"
                        style={{ cursor: "default" }}
                      >
                        <thead>
                          <tr>
                            <th className="col-1  th_font_size">Sr. No.</th>
                            <th className="col-2  th_font_size">
                              Application No.
                            </th>
                            <th className="col-2  th_font_size">
                              Applicant Name
                            </th>
                            <th className="col-2  th_font_size">Occupation</th>
                            <th className="col-2  th_font_size">Location</th>
                            <th className="col-1  th_font_size">Date</th>
                            <th className="col-1  th_font_size">Time(QLD)</th>
                            {accountType !== "head_office" && (
                            <th className="col-1  th_font_size">Action</th>
                            )}
                          </tr>
                        </thead>

                        <tbody>
                          {currentPageData
                            .slice(0, parseInt(dataPerPageButton))
                            .map((item, index) => (
                              <tr
                                key={index}
                                className={index % 2 === 0 ? "even" : "odd"}
                              >
                                <td className="td_font_size">{index + 1}</td>
                                <td className="td_font_size">
                                  {item?.applicant_application_no}
                                </td>

                                <td className="td_font_size">
                                  {item?.applicant_name}
                                </td>

                                {/* <td

                                  onClick={(event) =>
                                    handleNavigateToViewApplication(
                                      event,
                                      item?.id
                                    )
                                  }

                                  style={{
                                    color: "#055837",
                                    cursor: "pointer",
                                    fontSize: "17px",
                                  }}
                                  className="hover-underline"
                                >
                                  <span
                                    style={{
                                      display: "inline-block",
                                      transition:
                                        "transform 0.1s ease-in-out, text-decoration 0.1s ease-in-out",
                                    }}
                                    onMouseEnter={(e) => {
                                      e.target.style.transform = "scale(1.1)";
                                      e.target.style.textDecoration =
                                        "underline";
                                    }}
                                    onMouseLeave={(e) => {
                                      e.target.style.transform = "scale(1)";
                                      e.target.style.textDecoration = "none";
                                    }}
                                  
                                  >
                                    {item?.applicant_name}
                                  </span>
                                </td> */}

                                <td className="td_font_size">
                                  {item?.applicant_occupation}
                                </td>

                                <td className="td_font_size">
                                  {item?.applicant_location?.name}
                                </td>
                                <td className="td_font_size">
                                  {item?.applicant_interview_date}
                                </td>
                                <td className="td_font_size">
                                  {item?.applicant_interview_time}
                                </td>
                                {accountType !== "head_office" && (
                                <td className="btn-flex">
                                  <button
                                    data-bs-toggle="modal"
                                    data-bs-target="#edit_form2"
                                    className="btn btn-sm btn_yellow_green ms-1"
                                    disabled={item?.zoom_btn_disabled}
                                    onClick={() => handleZoomShow(item)}
                                  >
                                    <i className="bi bi-forward"></i>
                                  </button>

                                  <button
                                    data-bs-toggle="modal"
                                    data-bs-target="#delete_req2"
                                    className="btn btn-sm btn-danger ms-1"
                                    onClick={() =>
                                      handleCancelInterviewShow(item)
                                    }
                                  >
                                    {/* <i className="bi bi-trash-fill"></i> */}
                                    <i className="bi bi-x"></i>
                                  </button>
                                </td>
                                )}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <hr className="thick-hr" />





              <div className="pagination-container p-3">
  <div className="dataTables_info">
    Showing{" "}
    {currentPage === 1
      ? 1
      : (currentPage - 1) * dataPerPageButton + 1}{" "}
    to{" "}
    {Math.min(currentPage * dataPerPageButton, originalData.length)}{" "}
    of {originalData.length} entries
  </div>

  {/* <span 
    style={{
      background: 'linear-gradient(to bottom right, #fff9c4, #fff3e0)', 
      color: '#055837', 
      padding: '8px 12px',
      borderRadius: '5px',
      display: 'inline-block',
      cursor: "default"
    }}
  >
    Active Page : {currentPage}
  </span> */}

  <div className="pagination-buttons">
    <button
      className={`paginate_button ${currentPage === 1 ? "disabled" : ""}`}
      onClick={handleFirst}
      disabled={currentPage === 1}
    >
      First
    </button>

    <div style={{ display: 'flex', alignItems: 'center' }}>
      <button
        className={`paginate_button ${startPage === 1 ? "disabled" : ""}`}
        onClick={handlePrev}
        disabled={startPage === 1}
      >
        Previous
      </button>

      <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', flex: 1 }}>
        {Array.from({ length: visiblePages }, (_, index) => {
          const pageNumber = startPage + index;
          if (pageNumber > totalPages) return null;

          const isActive = currentPage === pageNumber;

          return (
            <button
              key={pageNumber}
              className={`paginate_button ${isActive ? "active" : ""}`}
              style={{
                display: 'inline-block',
                margin: '0 4px',
                backgroundColor: isActive ? "black" : "transparent",
                color: isActive ? "white" : "black",
              }}
              onClick={() => handlePageClick(pageNumber)}
            >
              {pageNumber}
            </button>
          );
        })}
      </div>

      <button
        className={`paginate_button ${startPage + visiblePages - 1 >= totalPages ? "disabled" : ""}`}
        onClick={handleNext}
        disabled={startPage + visiblePages - 1 >= totalPages}
      >
        Next
      </button>
    </div>

    <button
      className={`paginate_button ${currentPage === totalPages ? "disabled" : ""}`}
      onClick={handleLast}
      disabled={currentPage === totalPages}
    >
      Last
    </button>
  </div>
</div>











            </div>
          </div>
        </Card>
      </div>

      <NewBookingModal
        showModal={showNewInterviewModal}
        handleClose={handleNewInterviewModalClose}
        getNonAquatoDataFunction={getNonAquatoDataFunction}
      />

      <CancelInterview
        showModal={showCancelInterviewModal}
        handleClose={handleCancelInterviewClose}
        selectedItem={selectedItem}
        fetchPracticalBookingData={getNonAquatoDataFunction}
      />

      <ZoomDetails
        showModal={showZoomModal}
        handleClose={handleZoomClose}
        selectedItem={selectedItem}
        fetchPracticalBookingData={getNonAquatoDataFunction}
      />
    </>
  );
};

export default NonAqato;
