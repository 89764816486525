import React, { useState, useEffect } from "react";
import Reminder_Date_Chooser from "./Reminder_Date_Chooser";
import PopupComponent from "./PopupComponent/PopupComponent";
import { sendTIAgentActionFollowup_api } from "../../../api";
import { Toast } from "react-bootstrap";
import { toast } from "react-toastify";


// Helper function to format Date object to "DD/MM/YYYY"
const formatDate = (dateString) => {
  if (!dateString || dateString === "dd/mm/yyyy") {
    return "dd/mm/yyyy";
  }

  let date;

  // Handle date formats explicitly
  if (dateString.includes('/')) {
    const [day, month, year] = dateString.split('/').map(Number);
    date = new Date(year, month - 1, day);
  } else if (dateString.includes('-')) {
    date = new Date(dateString);
  } else {
    date = new Date(dateString);
  }

  // Check for invalid date
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

const Reminder_Date_Wrapper = ({ setLoading, AgentApplicantTiButton,API_Data, pointerID, getAllDataOfViewUpdateFunction }) => {
  const [reminderRender, setReminderRender] = useState(false);
  const [reminderDate, setReminderDate] = useState("dd/mm/yyyy");
  const [TIPaymentFolowupModal, setTIPaymentFolowupModal] = useState(false);

  useEffect(() => {
    if (API_Data?.reminder_date) {
      setReminderDate(API_Data.reminder_date || "dd/mm/yyyy"); // Fallback to placeholder if no reminder_date
    }
  }, [API_Data]);

  const handleToggleReminder = () => {
    setReminderRender(prev => !prev);
  };

  const handleReminderDateChange = (newDate) => {
    if (newDate === "") {
      setReminderDate("dd/mm/yyyy"); // Placeholder when cleared
    } else {
      setReminderDate(newDate);
    }
  };


  const sendTIAgentActionFollowup = async () => {
    if(pointerID === null){
          return;
        }
        try {
          setLoading(true);

          const formData = new FormData();

          formData.append("pointer_id", pointerID);
    
          const response = await sendTIAgentActionFollowup_api(formData);
    
          setLoading(false);
          console.log(response?.data?.response?.response, "response?.data?.response?.response");
          if (
            response &&
            response?.data &&
            response?.data?.response &&
            response?.data?.response?.response === true &&
            response?.data?.response?.data
          ) {

            getAllDataOfViewUpdateFunction(pointerID);

            setTIPaymentFolowupModal(false);
            toast.success(
              response?.data?.response?.success_msg || "TI Payment followup successfully."
            );
          } else {
            setLoading(false);
            console.error(
              "Error fetching data:",
              response?.data?.error_msg || "Unknown error"
            );

            toast.success(
              "Contact to IT Team"
            );

          }
          
        } catch (error) {
          setLoading(false);
          console.error("Error fetching data:", error);
        }
  };


  return (
    <>

<div className="reminderdate_wrapper"
style={{
  justifyContent: "flex-end"
}}
>
      <div style={{ cursor: "default" }}>
        <b>Reminder Date :</b>&nbsp;
      </div>
      {reminderRender ? (
        <Reminder_Date_Chooser
          reminderDate={reminderDate === "dd/mm/yyyy" ? "" : reminderDate}
          setReminderDate={handleReminderDateChange}
          setHideShowReminder={handleToggleReminder}
          pointerID={pointerID}
          getAllDataOfViewUpdateFunction={getAllDataOfViewUpdateFunction}
        />
      ) : (
        <a
          className="reminder_date_chooser"
          onClick={handleToggleReminder}
          style={{ fontWeight: 'bold', color: 'red' }}
        >
          {formatDate(reminderDate)}
        </a>
      )}
    </div>

    {AgentApplicantTiButton && (
      <button className="btn btn_green_yellow mt-1" id="border_magic_color" style={{
        float: "right",
      }}
      onClick={() => {setTIPaymentFolowupModal(true)}}
      >
        TI
        &nbsp;
        <i class="bi bi-arrow-right-circle-fill"></i>
      </button>
    )}


    {AgentApplicantTiButton && (
        <PopupComponent
          show={TIPaymentFolowupModal}
          onHide={() => {
            setTIPaymentFolowupModal(false);
          }}
          onYes={() => {
            sendTIAgentActionFollowup();
          }}
          ModalTitle="Confirm!"
          ModalBody={
            <>
              Are you sure you want to send TI Payment Followup?
            </>
          }
        />
      )}
    </>
  );
};

export default Reminder_Date_Wrapper;
