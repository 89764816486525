import React, { useState, useEffect } from "react";
import { Accordion, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import EnsureFileDownloadModal from "./EnsureFileDownloadModal/EnsureFileDownloadModal";
import DeleteFileModal from "./DeleteFileModal/DeleteFileModal";
import EditImageModal from "./EditImageModal/EditImageModal";
import QualificationVerificationModal from "./QualificationVerificationModal/QualificationVerificationModal";
import VerificationModal from "./VerificationModal/VerificationModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../Widgets/Loader/Loader";
import {
  postAddiotionalRequestInformationForSubmittedApplicationsOfApplicationManager,
  postSubmitStage2VerificationQualificationDocumentForSubmittedApplicationsOfApplicationManager,
  postRequestAgainTRAForSubmittedApplicationsOfApplicationManager,
  postDeleteCompanyForSubmittedApplicationsOfApplicationManager,
} from "../../../../api";
import DeleteStage2 from "./DeleteStage2/DeleteStage2";
import AddImageModal from "../Stage1/AddImageModal/AddImageModal";

const Stage2_R = ({
  current_status,
  current_stage,
  is_doc_deleted,
  ApiPageData,
  pointerID,
  getAllDataOfSubmittedApplicationsFunction,
}) => {
  const stage = "stage_2_R";
  const accountType = localStorage.getItem("AQATO_SuperAdminAccountType") || "";

  const [loading, setLoading] = useState(false);

  const [commentInputEnabled, setCommentInputEnabled] = useState(true);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [showCommentsInputs, setShowCommentsInputs] = useState({});
  const [comments, setComments] = useState({});
  const [newDocuments, setNewDocuments] = useState({});

  const [requestAddBTN_Disabled, setrequestAddBTN_Disabled] = useState(true);

  const [showEnsureFileDownloadModal, setShowEnsureFileDownloadModal] =
    useState(false);
  const [showDeleteFileModal, setShowDeleteFileModal] = useState(false);

  const [showDeleteStage2Modal, setShowDeleteStage2Modal] = useState(false);

  const [showEditImageModal, setShowEditImageModal] = useState(false);

  const [showAddImageModal, setShowAddImageModal] = useState(false);

  const [selectedCompanyID, setSelectedCompanyID] = useState("");


  const [
    showQualificationVerificationModal,
    setShowQualificationVerificationModal,
  ] = useState(false);

  const [showVerificationModal, setShowVerificationModal] = useState(false);

  useEffect(() => {
    setDocuments(ApiPageData?.db_files);
  }, [ApiPageData]);

  const handleEnsureFileDownloadOpen = () => {
    setShowEnsureFileDownloadModal(true);
  };

  const handleEnsureFileDownloadClose = () => {
    setShowEnsureFileDownloadModal(false);
  };

  const handleDeleteFileOpen = (doc) => {
    setSelectedDoc(doc);
    setShowDeleteFileModal(true);
  };

  const handleDeleteFileClose = () => {
    setShowDeleteFileModal(false);
  };

  const handleDeleteStage2Open = () => {
    setShowDeleteStage2Modal(true);
  };

  const handleDeleteStage2Close = () => {
    setShowDeleteStage2Modal(false);
  };

  const handleQualificationVerificationModalShow = () => {
    setShowQualificationVerificationModal(true);
  };

  const handleQualificationVerificationModalClose = () => {
    setShowQualificationVerificationModal(false);
  };

  const handleEditImageOpen = (doc) => {
    setSelectedDoc(doc);
    setShowEditImageModal(true);
    setCommentInputEnabled(true);
  };

  const handleEditImageClose = () => {
    setShowEditImageModal(false);
  };


  const handleAddImageClose = () => {
    setShowAddImageModal(false);
  };

  const [SelectedCompany, setSelectedCompany] = useState(null);
  const [SelectedCompanyName, setSelectedCompanyName] = useState("");

  const [isRequired, setIsRequired] = useState(false);

  const [showDeleteCompanyModal, setShowDeleteCompanyModal] = useState(false);

  const handleDeleteCompanyOpen = (docs, CompanyName) => {
    const index = 0;

    setSelectedCompany(docs[index]);
    setSelectedCompanyName(CompanyName);
    setShowDeleteCompanyModal(true);
  };

  const handleDeleteCompanyClose = () => {
    setShowDeleteCompanyModal(false);
    setSelectedCompany(null);
  };

  const handleRequestDeleteCompany = async (
    SelectedCompany,
    SelectedCompanyName
  ) => {
    const formData = new FormData();
    formData.append("pointer_id", pointerID);
    formData.append("stage", stage);
    formData.append("company_id", SelectedCompany?.company_id);
    formData.append("company_name", SelectedCompanyName);

    try {
      setLoading(true);

      const response =
        await postDeleteCompanyForSubmittedApplicationsOfApplicationManager(
          formData
        );

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        toast.success(
          response?.data?.response?.success_msg || "Deleted Successfully."
        );
        handleDeleteCompanyClose();

        getAllDataOfSubmittedApplicationsFunction(pointerID);
      } else {
        setLoading(false);
        console.error(
          "Error in deleting:",
          response?.data?.response?.error_msg || "Unknown error"
        );
        toast.error(response?.data?.response?.error_msg || "Error in deleting");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error in deleting:", error);
    }
  };

  const handleRequestAdditionalInfo = async () => {
    // documents.forEach((category) => {
    //   const [categoryName, docs] = Object.entries(category)[0];

    //   console.log(`Category: ${categoryName}`);

    //   docs.forEach((doc) => {
    //     // Log details of each document
    //     console.log("Document:");
    //     console.log(`  Name: ${doc.document_name}`);
    //     console.log(`  ID: ${doc.document_id}`);
    //     console.log(`  Is Additional: ${doc.is_additional ? "Yes" : "No"}`);
    //     console.log(
    //       `  Process Requested: ${doc.process_requested_file ? "Yes" : "No"}`
    //     );
    //     console.log(
    //       `  Comments: ${comments[doc.document_id] || "No comments"}`
    //     );
    //   });

    //   console.log("------------------------------------------------");
    // });

    // const documentNames = [];
    // const documentIds = [];
    // const isAdditional = [];
    // const documentComments = [];
    // const categoryNames = [];

    // documents.forEach((category) => {
    //   const [categoryName, docs] = Object.entries(category)[0];

    //   docs.forEach((doc) => {
    //     const comment = comments[doc?.document_id] || "No comments";

    //     if (comment !== "No comments") {
    //       documentNames.push(doc.document_name);
    //       documentIds.push(doc.document_id);
    //       isAdditional.push(doc.is_additional ? "Yes" : "No");
    //       documentComments.push(comment);
    //       categoryNames.push(categoryName);
    //     }
    //   });
    // });

    // const formData = new FormData();
    // formData.append("pointer_id", pointerID);
    // formData.append("stage", stage);
    // formData.append("document_names", JSON.stringify(documentNames));
    // formData.append("document_ids", JSON.stringify(documentIds));
    // formData.append("is_additional", JSON.stringify(isAdditional));
    // formData.append("document_comments", JSON.stringify(documentComments));
    // formData.append("category_names", JSON.stringify(categoryNames));

    const categoriesData = [];

    documents.forEach((category) => {
      const [categoryName, docs] = Object.entries(category)[0];

      const categoryDocuments = [];

      docs.forEach((doc) => {
        const comment = comments[doc?.document_id] || "No comments";

        // Include the document only if it has comments
        if (comment !== "No comments") {
          categoryDocuments.push({
            document_name: doc.document_name,
            document_id: doc.document_id,
            is_additional: doc.is_additional ? "Yes" : "No",
            comment: comment,
            is_company: doc?.is_company,
            company_id: doc.company_id,
          });
        }
      });

      // Add the category to the categoriesData array only if it has documents with comments
      if (categoryDocuments.length > 0) {
        categoriesData.push({
          category_name: categoryName,
          // Assuming you want to include is_company at the category level, you would need to ensure you have this data available
          // For now, this will include is_company for each document rather than at the category level.
          documents: categoryDocuments,
        });
      }
    });

    // Create a FormData object and append the necessary data
    const formData = new FormData();
    formData.append("pointer_id", pointerID);
    formData.append("stage", stage);
    formData.append("categories_data", JSON.stringify(categoriesData));

    // Now you can use formData to send it with a fetch or Axios request

    try {
      setLoading(true);

      const response =
        await postAddiotionalRequestInformationForSubmittedApplicationsOfApplicationManager(
          formData
        );

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        toast.success(
          response?.data?.response?.success_msg || "Requested Successfully."
        );
        handleVerificationModalClose();

        getAllDataOfSubmittedApplicationsFunction(pointerID);
      } else {
        setLoading(false);
        console.error(
          "Error in requesting:",
          response?.data?.response?.error_msg || "Unknown error"
        );
        toast.error(
          response?.data?.response?.error_msg || "Error in requesting"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error in requesting:", error);
    }
  };

  const handleRequestAdditionalInfoModalOpen = () => {
    setShowVerificationModal(true);
  };

  const handleVerificationModalClose = () => {
    setShowVerificationModal(false);
  };

  const handleVerificationModalYes = () => {
    handleRequestAdditionalInfo();
    handleVerificationModalClose();
  };

  // State for file input and error handling
  const [formValues, setFormValues] = useState({
    file: null,
    error: "",
    fileError: false,
  });

  // Handler for file input change
  const handleFileChangeForVerificationQualification = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type !== "application/pdf") {
        // Clear the file input and show an error message if the file is not a PDF
        event.target.value = ""; // Clear the file input
        setFormValues({
          file: null,
          error: "Only PDF files are allowed.",
          fileError: true,
        });
      } else {
        // Update the state if the file is a PDF
        setFormValues({
          file,
          error: "",
          fileError: false,
        });
      }
    }
  };

  // Handler for form submission
  const handleSubmitVerificationQualificationDocument = async (event) => {
    event.preventDefault();
    if (!formValues.file) {
      setFormValues((prevState) => ({
        ...prevState,
        fileError: true,
        error: "Please upload a valid PDF file.",
      }));
      return;
    }

    // Create a new FormData object
    const formData = new FormData();
    formData.append("pointer_id", pointerID);
    formData.append("stage", stage);
    formData.append("file", formValues.file);

    try {
      setLoading(true);

      const response =
        await postSubmitStage2VerificationQualificationDocumentForSubmittedApplicationsOfApplicationManager(
          formData
        );

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        toast.success(
          response?.data?.response?.success_msg ||
          "Verification Requested Successfully."
        );

        getAllDataOfSubmittedApplicationsFunction(pointerID);

        setFormValues({
          file: null,
          error: "",
          fileError: false,
        });
      } else {
        setLoading(false);
        console.error(
          "Error in Verification requesting:",
          response?.data?.response?.error_msg || "Unknown error"
        );
        toast.error(
          response?.data?.response?.error_msg || "Error in uploading."
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error in Verification requesting:", error);
      setFormValues((prevState) => ({
        ...prevState,
        error: "An error occurred while uploading the file.",
      }));
    }
  };

  const handleRequestAgainTRA = async (doc) => {
    const formData = new FormData();
    formData.append("pointer_id", pointerID);
    formData.append("stage", stage);
    formData.append("document_id", doc?.document_id);
    formData.append("document_name", doc?.document_name);

    try {
      setLoading(true);

      const response =
        await postRequestAgainTRAForSubmittedApplicationsOfApplicationManager(
          // formData,
          pointerID
        );

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        toast.success(
          response?.data?.response?.success_msg || "Requested Successfully."
        );
        handleTRAClose();

        // getAllDataOfSubmittedApplicationsFunction(pointerID);
      } else {
        setLoading(false);
        console.error(
          "Error in requesting:",
          response?.data?.response?.error_msg || "Unknown error"
        );
        toast.error(
          response?.data?.response?.error_msg || "Error in requesting"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error in requesting:", error);
    }
  };

  const handleShowInput = (docId) => {
    setShowCommentsInputs((prevState) => ({
      ...prevState,
      [docId]: !prevState[docId],
    }));
  };

  const handleCommentChange = (docId, value) => {
    setComments((prevState) => ({
      ...prevState,
      [docId]: value,
    }));

    setrequestAddBTN_Disabled(false);

    // if ( newDocuments[`${categoryName}-${index}`]?.trim() === "") {
    //   setrequestAddBTN_Disabled(false);
    // } else {
    //   setrequestAddBTN_Disabled(true);
    // }
  };

  // const handleInputChange = (categoryName, value) => {
  //   setNewDocuments((prev) => ({
  //     ...prev,
  //     [categoryName]: value,
  //   }));
  // };

  const handleInputChange = (index, categoryName, value) => {
    setNewDocuments((prev) => ({
      ...prev,
      [`${categoryName}-${index}`]: value, // Use categoryName and index as a unique key
    }));
  };

  // const handleAddNewDocument = (categoryName) => {
  //   const newCommentValue = newDocuments[categoryName];
  //   if (!newCommentValue.trim()) {
  //     return;
  //   }

  //   const newDocId = `new-doc-${Date.now()}`;
  //   const newDoc = {
  //     document_id: newDocId,
  //     document_name: "[T.B.A.] -",
  //     is_additional: true,
  //     document_link: null,
  //     process_requested_file: false,
  //     comment: newCommentValue,
  //   };

  //   setrequestAddBTN_Disabled(false);

  //   setDocuments((prevState) =>
  //     prevState.map((cat) => {
  //       const [catName, docs] = Object.entries(cat)[0];
  //       if (catName === categoryName) {
  //         return {
  //           [catName]: [...docs, newDoc],
  //         };
  //       }
  //       return cat;
  //     })
  //   );

  //   setShowCommentsInputs((prevState) => ({
  //     ...prevState,
  //     [newDocId]: false,
  //   }));

  //   setComments((prevState) => ({
  //     ...prevState,
  //     [newDocId]: newCommentValue,
  //   }));

  //   setNewDocuments((prev) => ({
  //     ...prev,
  //     [categoryName]: "",
  //   }));
  // };

  // Modify the handleAddNewDocument to use both index and categoryName
  const handleAddNewDocument = (index, categoryName, docs) => {
    // console.log("hfbdkuewhyhfkbewayf54webhjfb",docs);

    const newCommentValue = newDocuments[`${categoryName}-${index}`]; // Get value based on index and categoryName

    // Prevent adding if the document name is empty
    if (!newCommentValue.trim()) {
      return;
    }

    // const company_id = docs.length > 0 ? docs[0].company_id : null;

    // const is_company = company_id !== null ? true : false;

    const company_id =
      docs.length > 0 && docs[0].company_id !== "" ? docs[0].company_id : null;

    const is_company = company_id !== null && company_id !== "" ? true : false;

    const newDocId = `new-doc-${Date.now()}`;
    const newDoc = {
      document_id: newDocId,
      document_name: "[T.B.A.] -",
      is_additional: true,
      document_link: null,
      process_requested_file: false,
      comment: newCommentValue,
      company_id: company_id,
      is_company: is_company,
    };

    setrequestAddBTN_Disabled(false);

    // Add the new document to the specific category using index
    setDocuments((prevState) =>
      prevState.map((cat, i) => {
        if (i === index) {
          // Match by index
          const [catName, docs] = Object.entries(cat)[0];
          return {
            [catName]: [...docs, newDoc],
          };
        }
        return cat;
      })
    );

    // Initialize the comment input and comment for the new document
    setShowCommentsInputs((prevState) => ({
      ...prevState,
      [newDocId]: false,
    }));

    setComments((prevState) => ({
      ...prevState,
      [newDocId]: newCommentValue, // Initialize comment with the new comment value
    }));

    // Clear the input field for the new document name using index and categoryName
    setNewDocuments((prev) => ({
      ...prev,
      [`${categoryName}-${index}`]: "",
    }));
  };

  const [showTRAModal, setShowTRAModal] = useState(false);

  const handleTRAOpen = (doc) => {
    setSelectedDoc(doc);
    setShowTRAModal(true);
  };

  const handleTRAClose = () => {
    setShowTRAModal(false);
    setSelectedDoc(null);
  };

  const handleDeleteExtraAddedComment = (doc) => {
    if (!doc || !doc.document_id) return;

    // Capture the comment before deleting
    const commentToDelete = comments[doc.document_id];

    // Filter out the document with the matching document_id
    setDocuments((prevState) =>
      prevState.map((category) => {
        const [catName, docs] = Object.entries(category)[0];
        return {
          [catName]: docs.filter(
            (document) => document.document_id !== doc.document_id
          ),
        };
      })
    );

    // Remove comments associated with the deleted document
    setShowCommentsInputs((prevState) => {
      const updatedState = { ...prevState };
      delete updatedState[doc.document_id];
      return updatedState;
    });

    setComments((prevState) => {
      const updatedComments = { ...prevState };
      delete updatedComments[doc.document_id];
      return updatedComments;
    });

    // Show toast with the deleted comment
    toast.success(`Deleted comment: ${commentToDelete}`);
  };

  const renderDocuments = () => {
    return documents.map((category, index) => {
      const [categoryName, docs] = Object.entries(category)[0];
      const company_id = Object.entries(category)[0][1][0]["company_id"];

      return (
        <React.Fragment key={index}>
          {/* Category Header */}










          <tr className="tital_set">
            <td
              colSpan="3"
              style={{
                cursor: "default",
                backgroundColor: "#91a3b0",
                color: "white",
                textAlign: "left",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h6
                  style={{
                    fontWeight: "bold",
                    display: "inline-block",
                    marginLeft: "5px",
                  }}
                >
                  <i className="fas fa-city"></i> {categoryName}
                </h6>

                <div className="actions_btn">
                  {accountType !== "head_office" && index > 0 && (
                    // <Button
                    //   onClick={() => {
                    //     setShowAddImageModal(true);
                    //   }}
                    //   className="btn_yellow_green btn mb-3 btn-sm"
                    //   style={{
                    //     marginBottom: "0px !important"
                    //   }}
                    // >
                    //   <i class="bi bi-plus-lg"></i>
                    // </Button>

                    <button
                      className="btn btn-sm"
                      style={{
                        cursor: "pointer",
                        backgroundColor: "transparent",
                        border: "none",
                        // marginRight: "5px",
                        transition: "transform 0.1s ease",
                        outline: "none",
                        boxShadow: "none",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.transform = "scale(1.5)")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.transform = "scale(1.1)")
                      }
                      onFocus={(e) => (e.currentTarget.style.outline = "none")}
                      onClick={() => {
                        setShowAddImageModal(true);
                        setSelectedCompanyID(company_id);
                      }}
                      disabled={(is_doc_deleted == 1 || current_stage != stage)}
                    >
                      <i
                        className="bi bi-file-earmark-plus"
                        style={{ color: "white", fontSize: "1.1rem" }}
                      ></i>
                    </button>
                  )}


                  {accountType !== "head_office" && (
                    <>
                      {docs[0]?.is_company === true && docs[0]?.company_id && (
                        <button
                          className="btn btn-sm"
                          style={{
                            cursor: "pointer",
                            backgroundColor: "transparent",
                            border: "none",
                            marginRight: "5px",
                            transition: "transform 0.1s ease",
                            outline: "none",
                            boxShadow: "none",
                          }}
                          onMouseEnter={(e) =>
                            (e.currentTarget.style.transform = "scale(1.5)")
                          }
                          onMouseLeave={(e) =>
                            (e.currentTarget.style.transform = "scale(1.1)")
                          }
                          onFocus={(e) => (e.currentTarget.style.outline = "none")}
                          onClick={() => handleDeleteCompanyOpen(docs, categoryName)}
                          disabled={(is_doc_deleted == 1 || current_stage != stage)}
                        >
                          <i
                            className="bi bi-trash-fill"
                            style={{ color: "white", fontSize: "1.1rem" }}
                          ></i>
                        </button>
                      )}
                    </>

                  )}

                </div>



              </div>
            </td>
          </tr>























          {/* Render each document under the category */}
          {docs.map((doc) => {
            const isDisabled = doc.process_requested_file === true;

            const removedFile = (doc?.document_link === "javascript:void(0)") ? true : false;

            return (
              <tr
                key={doc.document_id}
                style={{
                  pointerEvents: isDisabled ? "none" : "auto",
                  opacity: isDisabled ? 0.5 : 1,
                }}
              >
                <td className="w-50">
                  <a
                    href={doc?.document_link}
                    target={(doc?.document_link === "javascript:void(0)") ? "" : "_blank"}
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: doc?.document_link ? "none" : "default",
                      cursor: doc?.document_link ? "pointer" : "default",
                    }}
                    onMouseEnter={(e) => {
                      if (doc?.document_link) {
                        e.currentTarget.style.textDecoration = "underline";
                      }
                    }}
                    onMouseLeave={(e) => {
                      if (doc?.document_link) {
                        e.currentTarget.style.textDecoration = "none";
                      }
                    }}
                    className={(removedFile === true) ? "opacity-low" : ""}
                  >
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        {doc?.document_name}
                        {doc?.is_additional !== "" && (
                          <span
                            style={{
                              fontWeight: "normal",
                              color: "black",
                              marginLeft: "5px",
                            }}
                          >
                            (Additional Information)
                          </span>
                        )}
                      </div>

                      {doc?.process_requested_file === true && (
                        <div
                          className="spinner-grow spinner-grow-sm"
                          role="status"
                          style={{
                            color: "#055837",
                            cursor: "default",
                          }}
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </span>
                  </a>
                </td>

                {/* Actions: Download, Edit, Delete, Show Input */}
                <td
                  className="d-flex justify-content-center"
                  style={{ textAlign: "center" }}
                >
                  <a
                    href={doc.document_link}
                    download={doc.document_name}
                    className={(removedFile === true) ? "btn btn-sm btn_yellow_green me-1 opacity-low" : "btn btn-sm btn_yellow_green me-1"}
                    target={(doc?.document_link === "javascript:void(0)") ? "" : "_blank"}
                    rel="noopener noreferrer"
                    style={{
                      cursor: isDisabled ? "not-allowed" : "pointer",
                    }}
                    disabled={
                      isDisabled || doc?.document_id?.startsWith("new-doc-") || removedFile === true
                    }
                  >
                    <i className="bi bi-download"></i>
                  </a>
                  {accountType !== "head_office" && (
                    <button
                      type="button"
                      className="btn btn-sm btn_green_yellow me-1"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      onClick={() => {
                        setIsRequired(doc?.is_required === "1" ? true : false);
                        handleEditImageOpen(doc);
                      }}
                      style={{
                        cursor: isDisabled ? "not-allowed" : "pointer",
                      }}
                      disabled={
                        isDisabled ||
                        doc?.edit_file_disable === true ||
                        doc?.document_id?.startsWith("new-doc-") ||
                        (removedFile === true && is_doc_deleted == 1)
                      }
                    >
                      <i className="bi bi-pencil-square"></i>
                    </button>
                  )}

                  <button
                    href="javascript:void(0)"
                    className="btn btn-sm btn-danger me-1"
                    onClick={() => {
                      if (!doc?.document_id?.startsWith("new-doc-")) {
                        handleDeleteFileOpen(doc);
                      } else if (doc?.document_id?.startsWith("new-doc-")) {
                        handleDeleteExtraAddedComment(doc);
                      }
                    }}
                    style={{
                      cursor: isDisabled ? "not-allowed" : "pointer",
                    }}
                    disabled={
                      isDisabled ||
                      doc?.document_name === "TRA Application Form" ||
                      doc?.delete_file_disable === true ||
                      (removedFile === true && is_doc_deleted == 1) ||
                      doc?.is_required === "1"
                    }
                  >
                    <i className="bi bi-trash-fill"></i>
                  </button>

                  <button
                    href="javascript:void(0)"
                    className="btn btn-sm btn_green_yellow me-2"
                    onClick={() => {
                      if (doc?.document_name === "TRA Application Form") {
                        handleTRAOpen(doc);
                      } else {
                        handleShowInput(doc?.document_id);
                      }
                    }}
                    style={{
                      cursor: isDisabled ? "not-allowed" : "pointer",
                    }}
                    disabled={(removedFile === true && is_doc_deleted == 1) || (current_status === true)}
                  // disabled={isDisabled || doc?.comment_btn_disable === true || removedFile === true}
                  >
                    {doc?.document_name === "TRA Application Form" ? (
                      <i className="bi bi-arrow-clockwise"></i>
                    ) : (
                      <i
                        className={`bi ${showCommentsInputs[doc?.document_id]
                            ? "bi-check-lg"
                            : "bi-chat-left-dots"
                          }`}
                      ></i>
                    )}
                  </button>
                </td>

                {/* Comment Input */}
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={comments[doc.document_id] || ""}
                    onChange={(e) =>
                      handleCommentChange(doc.document_id, e.target.value)
                    }
                    disabled={
                      !showCommentsInputs[doc.document_id] || isDisabled
                    }
                  />
                </td>
              </tr>
            );
          })}

          {/* New Document Row - Always shown at the end */}
          <tr>
            <td
              className="w-50"
              style={{ textAlign: "left", verticalAlign: "middle" }}
            >
              <span
                style={{
                  fontWeight: "bold",
                  color: "#055837",
                  cursor: "default",
                }}
              >
                Additional Information
              </span>
            </td>

            <td
              className="d-flex justify-content-center align-items-center"
              style={{ textAlign: "center", height: "100%" }} // Full height for vertical centering
            >
              <button
                type="button"
                className="btn btn-sm btn_green_yellow mt-1"
                style={{ height: "35px" }}
                // onClick={() => handleAddNewDocument(categoryName)}
                // disabled={!newDocuments[categoryName]?.trim()}
                onClick={() => handleAddNewDocument(index, categoryName, docs)}
                disabled={!newDocuments[`${categoryName}-${index}`]?.trim()}
              >
                <i className="bi bi-plus-circle"></i> Add &rarr;
              </button>
            </td>

            <td>
              <input
                type="text"
                className="form-control"
                // value={newDocuments[categoryName] || ""}
                value={newDocuments[`${categoryName}-${index}`] || ""}
                // onChange={(e) =>
                //   handleInputChange(categoryName, e.target.value)
                // }
                // onChange={(e) =>
                //   handleInputChange(index, categoryName, e.target.value)
                // }

                onChange={(e) => {
                  handleInputChange(index, categoryName, e.target.value);
                  setrequestAddBTN_Disabled(true);
                }}
                disabled={!commentInputEnabled || is_doc_deleted == 1}
                style={{
                  border: "2px solid #fecc00",
                  borderRadius: "5px",
                  padding: "0.375rem 0.75rem",
                }}
              />
            </td>
          </tr>
        </React.Fragment>
      );
    });
  };

  return (
    <>
      {loading && <Loader />}

      <Accordion.Item eventKey="6">
        <Accordion.Header className="accordion_header">
          <i className="bi bi-folder-fill mx-2"></i> Stage 2 (R)- Documentary
          Evidence
        </Accordion.Header>
        <Accordion.Body>
          <>
            <div className="row">
              <div id="delete_stage_2" className="mb-2 col-12 col-md-10">
                <Button
                  onClick={handleEnsureFileDownloadOpen}
                  className="btn_yellow_green btn mb-3"
                >
                  Download All Stage 2 (R) Documents{" "}
                  <i className="bi bi-download"></i>
                </Button>
              </div>

              {/* {accountType !== "head_office" && (
                <>
                  <div id="delete_stage_2" className="mb-2 col-12 col-md-2">
                    <button
                      onClick={handleDeleteStage2Open}
                      className="btn_green_yellow btn"
                      style={{ float: "right" }}
                    >
                      Delete Stage 2
                    </button>
                  </div>
                </>
              )} */}
            </div>

            <table
              className="table table-striped table-bordered w-100"
              style={{
                borderRadius: "10px",
                overflow: "hidden",
                borderCollapse: "separate",
                borderSpacing: 0,
                border: "0.5px solid #fecc00",
              }}
            >
              <thead>
                <tr
                  style={{
                    backgroundColor: "#333",
                    color: "white",
                    cursor: "default",
                  }}
                >
                  <th className="col-md-4 col-4">Document Name</th>
                  <th className="col-md-2 col-2 text-center">Action</th>
                  <th className="col-md-6 col-6">Comments</th>
                </tr>
              </thead>
              <tbody>
                {renderDocuments()}

                <tr>
                  <td colSpan="3">
                    <Button
                      type="submit"
                      className="btn btn-sm btn_green_yellow me-2"
                      variant="green_yellow"
                      style={{ float: "right" }}
                      onClick={handleRequestAdditionalInfoModalOpen}
                      disabled={requestAddBTN_Disabled || is_doc_deleted == 1}
                    >
                      Request Additional Info
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>

            {ApiPageData?.first_btn_send === true && (
              <div className="col-md-12 my-3 mb-3 mt-1">
                <div className="row">
                  <div className="col-12">
                    <button
                      id="Send_Employment_Verification_Email"
                      className="btn btn_green_yellow"
                      onClick={handleQualificationVerificationModalShow}
                      disabled={is_doc_deleted == 1}
                    >
                      Send Employment Verification Email
                    </button>
                  </div>
                </div>
              </div>
            )}

            {ApiPageData?.second_btn_upload === true && (
              <div className="col-12 my-3 mt-1">
                <form
                  id="verify_email_stage_1"
                  method="post"
                  onSubmit={handleSubmitVerificationQualificationDocument}
                >
                  <h6>Verification - Employment</h6>
                  <div className="row">
                    <div className="col-8 col-md-4 d-flex">
                      <input
                        name="file"
                        type="file"
                        className="form-control s1"
                        required
                        onChange={handleFileChangeForVerificationQualification}
                        accept=".pdf"
                        style={{ height: "35px" }}
                        disabled={is_doc_deleted == 1}
                      />
                    </div>
                    <div className="col-2 col-md-2">
                      <button
                        onClick={handleSubmitVerificationQualificationDocument}
                        type="submit"
                        className="btn btn_green_yellow"
                        disabled={is_doc_deleted == 1}
                      >
                        Upload
                      </button>
                    </div>
                    <div className="text-danger">
                      {formValues.error && <span>{formValues.error}</span>}
                    </div>
                  </div>
                </form>
              </div>
            )}
          </>
        </Accordion.Body>
      </Accordion.Item>

      <EnsureFileDownloadModal
        show={showEnsureFileDownloadModal}
        onHide={handleEnsureFileDownloadClose}
        stage={stage}
        pointerID={pointerID}
        getAllDataOfSubmittedApplicationsFunction={
          getAllDataOfSubmittedApplicationsFunction
        }
      />
      <DeleteFileModal
        show={showDeleteFileModal}
        onHide={handleDeleteFileClose}
        selectedDoc={selectedDoc}
        pointerID={pointerID}
        getAllDataOfSubmittedApplicationsFunction={
          getAllDataOfSubmittedApplicationsFunction
        }
        stage={stage}
      />

      <DeleteStage2
        show={showDeleteStage2Modal}
        onHide={handleDeleteStage2Close}
        pointerID={pointerID}
        getAllDataOfSubmittedApplicationsFunction={
          getAllDataOfSubmittedApplicationsFunction
        }
        stage={stage}
      />

      <EditImageModal
        isRequired = {isRequired}
        show={showEditImageModal}
        onHide={handleEditImageClose}
        selectedDoc={selectedDoc}
        pointerID={pointerID}
        getAllDataOfSubmittedApplicationsFunction={
          getAllDataOfSubmittedApplicationsFunction
        }
        stage={stage}
      />
      <AddImageModal
        current_stage = {current_stage}
        show={showAddImageModal}
        onHide={handleAddImageClose}
        pointerID={pointerID}
        getAllDataOfSubmittedApplicationsFunction={
          getAllDataOfSubmittedApplicationsFunction
        }
        stage={stage}
      />

      {showQualificationVerificationModal && (
        <QualificationVerificationModal
          show={showQualificationVerificationModal}
          handleClose={handleQualificationVerificationModalClose}
          pointerID={pointerID}
          getAllDataOfSubmittedApplicationsFunction={
            getAllDataOfSubmittedApplicationsFunction
          }
          stage={stage}
          call={true}
        />
      )}

      {showVerificationModal && (
        <VerificationModal
          show={showVerificationModal}
          onClose={handleVerificationModalClose}
          onYes={handleVerificationModalYes}
          Title="Confirm !"
          Message={
            <>
              Are you sure you want to request{" "}
              <span style={{ fontWeight: "bold", color: "#055837" }}>
                Additional Information
              </span>
              ?
            </>
          }
        />
      )}

      {showTRAModal && (
        <VerificationModal
          show={showTRAModal}
          onClose={handleTRAClose}
          onYes={() => handleRequestAgainTRA(selectedDoc)}
          Title="Confirm !"
          Message={
            <>
              Are you sure you want to request again{" "}
              <span style={{ fontWeight: "bold", color: "#055837" }}>
                TRA Application Form
              </span>
              ?
            </>
          }
        />
      )}

      {showDeleteCompanyModal && SelectedCompany && SelectedCompanyName && (
        <VerificationModal
          show={showDeleteCompanyModal}
          onClose={handleDeleteCompanyClose}
          onYes={() =>
            handleRequestDeleteCompany(SelectedCompany, SelectedCompanyName)
          }
          Title="Confirm Delete!"
          Message={
            <>
              Are you sure you want to request delete{" "}
              <span style={{ fontWeight: "bold", color: "red" }}>
                {SelectedCompanyName}
              </span>
              ?
            </>
          }
        />
      )}
    </>
  );
};

export default Stage2_R;
